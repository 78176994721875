import type { PlayerHistoryData, PlayersData, ThresholdsData, LeagueData } from "../../types";

import { unknown_player } from "../../constants";
import { get_radios, get_elem } from "../../util";

import { init_mock_data } from "./mock";
import { init_radar, update_radar } from "./radar";
import { search_and_color_names } from "./search";
import { init_graph, update_graph } from "./graph";
import { update_card } from "./card";

const plt = Bokeh.Plotting;

function set_callbacks(update_callback: () => void): void {
    const radar_radios = get_radios("player-radar-type");
    const graph_radios = get_radios("player-stats-type");
    const radios = [...radar_radios, ...graph_radios];
    radios.forEach(e => e.onclick = update_callback)

    const search = get_elem("player-search");
    search.addEventListener("keydown", (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    });
    search.addEventListener('paste', (e) => {
        e.preventDefault();
        const plain_text = e.clipboardData.getData("text/plain");
        document.execCommand("insertText", false, plain_text);
    });
    const callback = (e: KeyboardEvent) => {
        if (!e.metaKey && !e.ctrlKey && !["Control", "Meta"].includes(e.key)) {
            update_callback();
        }
    };
    search.addEventListener("change", callback);
    search.addEventListener("paste", callback);
    search.addEventListener("keyup", callback);
}

function update_url(selected_players: string[]): void {
    const url_params = new URLSearchParams(window.location.search);
    url_params.set("p", selected_players.join(" "));
    if (selected_players.length === 0) {
        url_params.delete("p");
    }
    const new_path = `${window.location.pathname}?${url_params.toString()}${location.hash}`;
    history.replaceState(null, "", new_path);
}

function set_players_from_params(): void {
    const search = get_elem("player-search");
    const url_params = new URLSearchParams(window.location.search);
    if (url_params.has("p")) {
        search.innerHTML = url_params.get("p").split("+").filter(p => p.toLowerCase() !== "zaptorzap").join(" ");
    }
}

function update_sources(player_history: PlayerHistoryData, players: PlayersData, thresholds: ThresholdsData): void {
    let selected_players = search_and_color_names(players);
    update_url(selected_players);
    if (selected_players.length == 0) {
        selected_players = [unknown_player];
    }

    update_card(players, thresholds, selected_players[0]);
    update_radar(players, selected_players);
    update_graph(player_history, selected_players);
}

export function init(player_history: PlayerHistoryData, league: LeagueData, players: PlayersData, thresholds: ThresholdsData): void {
    init_mock_data(players, player_history);

    const radar = init_radar();
    const graph = init_graph(league);

    set_players_from_params();
    set_callbacks(() => update_sources(player_history, players, thresholds));
    update_sources(player_history, players, thresholds);

    plt.show(radar, "#player-radar");
    plt.show(graph, "#player-stats");
}
