import type * as TBokeh from "bokehjs";
import type { PlayerCountData } from "../../types";

import { get_radios, windowed_average } from "../../util";
import { patch_plot_theme } from "../../bokeh";

const plt = Bokeh.Plotting;
const colors = ["#2293e3", "#ff7f0e", "#2ca02c", "#9f9f9f"];

const source = new Bokeh.ColumnDataSource({ data: {
    scrape_date: [],
    quickplay_players: [],
    quickplay_spectators: [],
    league_players: [],
    league_queue: [],
    room_players: [],
    room_spectators: [],
    rooms: [],
    total: [],
    total_smooth: [],
} });

function init_graph(source: TBokeh.ColumnDataSource): TBokeh.LayoutDOM {
    const now = new Date().getTime();
    const last_full_hour = now - (now % 3600000);

    const zoom = plt.figure({
        tools: "xpan",
        toolbar_location: null,
        min_border_top: 0,
        y_axis_location: "right",
        x_axis_type: "datetime",
        sizing_mode: "stretch_both",
        x_range: [last_full_hour - (24 * 3600 * 1000), last_full_hour],
    });

    const timeline = plt.figure({
        x_axis_type: "datetime",
        plot_height: 75,
        sizing_mode: "stretch_width",
        tools: "",
        y_axis_type: null,
        toolbar_location: null,
    });

    patch_plot_theme(timeline);
    patch_plot_theme(zoom);

    // @ts-ignore
    const range_tool = new Bokeh.RangeTool({ x_range: zoom.x_range });
    range_tool.overlay.fill_color = "cornflowerblue";
    range_tool.overlay.fill_alpha = 0.3;
    timeline.add_tools(range_tool);
    timeline.toolbar.active_multi = range_tool;

    timeline.line(
        { field: "scrape_date" },
        { field: "total_smooth" },
        {
            source,
            line_width: 1.5,
            line_color: colors[3],
        }
    );

    const labels = ["in quickplay", "in league", "in rooms", "TOTAL"];
    const columns = ["quickplay_players", "league_players", "room_players", "total"];

    const lines = columns.map((l, i) => {
        return zoom.line(
            { field: "scrape_date" },
            { field: l },
            {
                source,
                line_width: 1.5,
                line_color: colors[i],
                legend: labels[i],
            }
        );
    });

    zoom.legend.location = "top_left";
    zoom.legend.padding = 4;
    zoom.legend.orientation = "horizontal";

    const crosshair = new Bokeh.CrosshairTool({
        dimensions: "height",
        line_color: "white",
    });
    const hover = new Bokeh.HoverTool({
        tooltips: [["time", "$data_x{%H:%M UTC}"] as [string, string]].concat(labels.map((l, i) => [l, `@${columns[i]}{0}`])),
        formatters: {
            "$data_x": "datetime",
        },
        mode: "vline",
        renderers: [lines[3]],
    });
    zoom.add_tools(hover);
    zoom.add_tools(crosshair);

    return new Bokeh.Column(
        {
            children: [zoom, timeline],
            sizing_mode: "stretch_both",
            // @ts-ignore
            merge_tools: false
        }
    );
}

function set_callbacks(player_count: PlayerCountData): void {
    get_radios("misc-count-resolution").forEach(r => r.onclick = () => update(player_count));
}

function get_resolution(): number {
    const samples: Record<string, number> = {
        "5M": 0,
        "15M": 3 - 1,
        "1H": 12 - 1,
        "6H": 6 * 12 - 1,
        "1D": 24 * 12 - 1,
    }
    return samples[get_radios("misc-count-resolution").find(r => r.checked).value];
}

function update(player_count: PlayerCountData): void {
    const smoothing = get_resolution();

    // TODO: skip samples every N samples when smoothing
    // const labels = ["quickplay_players", "quickplay_spectators", "league_players", "league_queue", "room_players", "room_spectators", "rooms"];
    const labels = ["quickplay_players", "league_players", "room_players"];
    const values: Record<string, number[]> = {};

    labels.forEach(label => {
        const array = player_count[label];
        values[label] = (smoothing > 1) ? array.map((v, i) => windowed_average(array, i, smoothing)) : array;
    });

    const total = values["quickplay_players"].map((qp, i) => qp + values["league_players"][i] + values["room_players"][i]);
    const real_total = player_count["quickplay_players"].map((qp, i) => qp + player_count["league_players"][i] + player_count["room_players"][i]);
    const total_smooth = real_total.map((v, i) => windowed_average(real_total, i, 287));

    const dates = player_count.scrape_date.map(d => ((d * 300) + 1587886801) * 1000);
    source.data = {
        scrape_date: dates,
        ...values,
        total,
        total_smooth,
    };
}

export function init(player_count: PlayerCountData) {
    const graph = init_graph(source);
    set_callbacks(player_count);
    update(player_count);
    plt.show(graph, "#player-count");
}
