export function patch_object(object: any, patch: any) {
    Object.assign(object, patch)
}

export function patch_property(object: any, property: any, patch: any) {
    if (Array.isArray(object[property])) {
        for (let i = 0; i < object[property].length; ++i) {
            Object.assign(object[property][i], patch);
        }
    }
    else if (typeof object[property] !== undefined) {
        Object.assign(object[property], patch);
    }
}

export function range(from: number, to?: number) {
    if (typeof to === "undefined") {
        to = from;
        from = 0;
    }
    const length = to - from;
    return Array.from({ length }, (v, n) => n + from);
}
