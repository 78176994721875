import { range } from "../../util";

/**
 * Modified from:
 * https://github.com/wgoto/optimal-std-dev/blob/master/src/index.js
 */
function standard_deviation(values: number[]) {
    const I = values.reduce(
        (I, x, k) => {
            k = k + 1;
            return {
              Sg: I.Sg + x,
              Mk: k === 1 ? x : I.Mk + ((x - I.Mk) / k),
              Qk: k === 1 ? 0 : I.Qk + ((k - 1) * Math.pow(x - I.Mk, 2) / k),
            }
        },
        { Sg: 0, Mk: 0, Qk: 0 }
    );
    const n = values.length;
    return {
        mean: I.Sg / n,
        sd: Math.sqrt(I.Qk / n),
    };
}

export function standard_deviation_from_point_cloud(values_x: number[], values_y: number[], n_stddev_bars: number) {
    // Zip and sort by x
    const xy = values_x
        .map((x, i) => [x, values_y[i]])
        .filter(xy => !isNaN(xy[0]) && !isNaN(xy[1]))
        .sort((a, b) => a[0] - b[0]);
    // Determine x step
    const xy_min = xy[0][0];
    const xy_max = xy[xy.length - 1][0];
    const step = (xy_max - xy_min) / n_stddev_bars;
    // stddev is calculated on wide bars, base is the center of each bar
    const base = range(n_stddev_bars)
        .map(i => xy_min + i * step + 0.5 * step);

    let xy_index = 0;
    let lower: number[] = [];
    let upper: number[] = [];
    range(n_stddev_bars).forEach(i => {
        let current_bar = [];
        while (true) {
            if (xy_index >= xy.length) {
                break;
            }
            const [x, y] = xy[xy_index];
            if (x > xy_min + (i + 1) * step) {
                break;
            }
            xy_index += 1;
            current_bar.push(y);
        }
        const {mean, sd} = (current_bar.length > 2) ? standard_deviation(current_bar) : { mean: NaN, sd: NaN };
        lower.push(mean - sd);
        upper.push(mean + sd);
    });

    return { base, lower, upper };
}
