export function get_caret(node: HTMLElement): number {
    node.focus();
    const _range = document.getSelection().getRangeAt(0);
    const range = _range.cloneRange();
    range.selectNodeContents(node);
    range.setEnd(_range.endContainer, _range.endOffset);
    return range.toString().length;
}

export function set_caret(node: HTMLElement, index: number): void {
    const walker = document.createTreeWalker(
        node,
        NodeFilter.SHOW_TEXT,
        { acceptNode: (node) => NodeFilter.FILTER_ACCEPT },
        false
    );

    let position = 0;

    while (walker.nextNode()) {
        position += (walker.currentNode as Text).length;
        if (position >= index) {
            const node_start = position - (walker.currentNode as Text).length;
            const offset = index - node_start;

            const range = document.createRange();
            range.setStart(walker.currentNode, offset);
            range.collapse(true);

            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);

            break;
        }
    }
};
