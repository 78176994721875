import { PlayerCountData, LeagueData, ThresholdsData, PlayerHistoryData, PlayersData } from "../types";

import { get_elem } from "../util";

import * as misc from "./misc";
import * as tetra_league from "./tetra_league";
import * as rank_thresholds from "./ranks";
import * as player_stats from "./player_stats";
import * as editor from "./editor";
import * as skins from "./skins";


function get_json<T>(file: string): Promise<T> {
    // Named .js because the server doesn't gzip *.json
    return fetch(`data/${file}.js`).then(response => response.json());
}

function remove_spinner(id: string): void {
    get_elem(`loading-${id}`).remove();
}

export async function init() {
    player_stats.init_snapshot_button();
    skins.init_buttons();
    editor.init();

    get_json<PlayerCountData>("player_count").then(player_count => {
        remove_spinner("misc");
        misc.init(player_count);
    });

    const league = await get_json<LeagueData>("league");
    remove_spinner("tetra-league");
    tetra_league.init(league);

    const thresholds = await get_json<ThresholdsData>("thresholds");
    rank_thresholds.init(thresholds);

    const player_history = await get_json<PlayerHistoryData>("player_history");
    const players = await get_json<PlayersData>("players");

    remove_spinner("player-stats");
    remove_spinner("player-radar");
    player_stats.init(player_history, league, players, thresholds);
}
