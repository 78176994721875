import type { RLEArray, PlayersData, PlayerHistoryData } from "../../types";

import { range } from "../../util";
import { unknown_player } from "../../constants";

import { compress_date } from "./date";


function interpolate(from: number, to: number, n: number): number[] {
    return range(n).map(i => from + (i / n) * (to - from));
}

function climbing_sine(max: number, n: number): RLEArray {
    return range(n)
        .map(x => x / n)
        .map(x => max * (0.25 * Math.sin(2 * Math.PI * x) + x))
        .map(x => `1:${x};`)
        .join("");
}

export function init_mock_data(players: PlayersData, player_history: PlayerHistoryData): void {
    players.latest_stats[unknown_player] = players.maximums
    player_history.stats[unknown_player] = {
        TR:  climbing_sine(25000, 1000),
        glk: climbing_sine(3000, 1000),
        RD:  climbing_sine(100, 1000),
        VS:  climbing_sine(300, 1000),
        pps: climbing_sine(5, 1000),
        apm: climbing_sine(300, 1000),
        date: interpolate(
            compress_date(player_history.timestamp_offset)(player_history.timestamp_offset * 1000),
            compress_date(player_history.timestamp_offset)(new Date().getTime()),
            1000
        ),
    }
    
    players.unranked_stats["$median"].rank = "z";
    players.unranked_stats["$average"].rank = "z";
    players.unranked_stats["$maximum"].rank = "z";        
}
