import type * as TBokeh from "bokehjs";
import type { LeagueData, PlayerHistoryData, HistoryStat } from "../../types";

import { range, get_radios, decode_rle, windowed_average } from "../../util";
import { patch_plot_theme, Category10_10 } from "../../bokeh";
import { new_formula_date, unknown_player } from "../../constants";

import { uncompress_date } from "./date";

const plt = Bokeh.Plotting;
const palette = Category10_10;

export const graph_source = new Bokeh.ColumnDataSource({ data: {
    date: [],
    tr_ys: [],
    vs_ys: [],
    pps_ys: [],
    apm_ys: [],
    player: [],
    color: [],
} });

export const timeline_source = new Bokeh.ColumnDataSource({ data: {
    date: [],
    tr: [],
    rank: [],
} });

export function init_graph(league: LeagueData): TBokeh.LayoutDOM {
    const color_mapper = new Bokeh.CategoricalColorMapper({
        factors: league.ranks.map(r => r.rank.toUpperCase()),
        palette: league.ranks.map(r => r.color)
    });

    const x_locations: TBokeh.Location[] = ["above", "above", null, null];
    const y_locations: TBokeh.Location[] = ["left", "right", "left", "right"];
    const sp = 4;
    const tp = 25;
    const sd = 60;
    const border_top    = [tp, tp, sp, sp];
    const border_bottom = [sp, sp, sp, sp];
    const border_left   = [sd, sp, sd, sp];
    const border_right  = [sp, sd, sp, sd];

    const graphs = range(4).map(i =>
        plt.figure({
            tools: "xpan",
            toolbar_location: null,
            x_axis_type: "datetime",
            x_axis_location: x_locations[i],
            y_axis_location: y_locations[i],
            min_border_top: border_top[i],
            min_border_bottom: border_bottom[i],
            min_border_left: border_left[i],
            min_border_right: border_right[i],
            sizing_mode: "stretch_both",
        })
    );

    const timeline = plt.figure({
        y_range: graphs[0].y_range,
        x_axis_type: "datetime",
        y_axis_type: null,
        plot_height: 150,
        sizing_mode: "stretch_width",
        tools: "",
        toolbar_location: null,
        name: "timeline",
    });

    (timeline.y_range as TBokeh.DataRange1d).range_padding = 0.45;

    const common_range = graphs[0].x_range;
    graphs.forEach((g, i) => {
        g.x_range = common_range;
        if (x_locations[i] === null) {
            g.xaxis[0].visible = false;
        }
        patch_plot_theme(g);
    });

    const range_tool = new Bokeh.RangeTool({ x_range: common_range as TBokeh.Range1d });
    range_tool.overlay.fill_color = "cornflowerblue";
    range_tool.overlay.fill_alpha = 0.3;
    timeline.add_tools(range_tool);
    timeline.toolbar.active_multi = range_tool;

    patch_plot_theme(timeline);

    const label_format: Record<string, string> = {
        tr: "{0}",
        vs: "{0.0}",
        apm: "{0.0}",
        pps: "{0.00}"
    };

    ["tr", "vs", "apm", "pps"].forEach((label, i) => {
        const lines = graphs[i].multi_line(
            { field: "date" },
            { field: `${label}_ys` },
            {
                source: graph_source,
                line_width: 1.5,
                line_color: { field: "color" },
            }
        );
        const crosshair = new Bokeh.CrosshairTool({
            dimensions: "height",
            line_color: "white",
        });
        const hover = new Bokeh.HoverTool({
            tooltips: [
                ["name", "@player"],
                ["date", "$data_x{%B %-d %Y %H:%M UTC}"],
                [label.toUpperCase(), "$data_y" + label_format[label]],
            ],
            formatters: {
                "$data_x": "datetime",
            },
            mode: "vline",
            renderers: [lines],
        });
        graphs[i].add_tools(hover);
        graphs[i].add_tools(crosshair);
    });

    timeline.multi_line(
        { field: "date" },
        { field: "tr_ys" },
        {
            source: graph_source,
            line_width: 1.5,
            line_color: { field: "color" },
        }
    );

    const timeline_labels = new Bokeh.LabelSet({
        x: { field: "date" },
        y: { field: "tr" },
        text: { field: "rank" },
        source: timeline_source,

        render_mode: "canvas",
        x_offset: 3,
        y_offset: 3,
        text_align: "left",
        text_baseline: "bottom",
        // @ts-ignore
        text_color: { field: "rank", transform: color_mapper },
        text_font: "pfw",
    });
    timeline.add_layout(timeline_labels);
    timeline.circle(
        { field: "date" },
        { field: "tr" },
        {
            source: timeline_source,
            size: 3,
            // @ts-ignore
            color: { field: "rank", transform: color_mapper },
        }
    );

    graphs[0].yaxis[0].axis_label = "TR";
    graphs[1].yaxis[0].axis_label = "VS";
    graphs[2].yaxis[0].axis_label = "apm";
    graphs[3].yaxis[0].axis_label = "pps";

    const new_formula = new Bokeh.Span({
        location: new_formula_date,
        dimension: "height",
        line_color: "white",
        line_dash: [3, 3],
        line_width: 1,
        line_alpha: 0.5,
    });

    const new_formula_label = new Bokeh.Label({
        x: new_formula_date,
        y: 0,
        y_offset: 5,
        y_units: "screen",
        text: "new TR formula",
        render_mode: "canvas",
        angle: Math.PI / 2,
        text_align: "left",
        text_color: "white",
        text_font: "pfw",
        text_alpha: 0.5,
    });

    graphs[0].add_layout(new_formula);
    graphs[0].add_layout(new_formula_label);

    return new Bokeh.GridBox(
        {
            children: [
                [graphs[0], 0, 0, 1, 1],
                [graphs[1], 0, 1, 1, 1],
                [graphs[2], 1, 0, 1, 1],
                [graphs[3], 1, 1, 1, 1],
                [timeline,  2, 0, 1, 2]
            ],
            sizing_mode: "stretch_both",
        }
    );
}

export function update_graph(player_history: PlayerHistoryData, selected_players: string[]): void {
    const smoothing = get_radios("player-stats-type").find(r => r.checked).value;
    const smooth_window = smoothing == "SMOOTH" ? 48 : 0;

    const labels: HistoryStat[] = ["TR", "VS", "pps", "apm"];
    const values = labels.map(label => {
        return selected_players.map(p => {
            const array = decode_rle(player_history.stats[p][label]);
            if (array.length < smooth_window) return array;
            return (smooth_window > 0)
                ? array.map((_v: number, i: number) => windowed_average(array, i, smooth_window))
                : array;
        });
    });
    const dates = selected_players.map(p => {
        return player_history.stats[p]["date"].map(uncompress_date(player_history.timestamp_offset));
    });

    if (selected_players.length === 1 && selected_players[0] !== unknown_player && !selected_players[0].startsWith("$")) {
        const p = player_history.ranks[selected_players[0]];
        timeline_source.data = {
            date: p.date.map(uncompress_date(player_history.timestamp_offset)),
            tr: p.tr,
            rank: p.rank.map(r => r.toUpperCase()),
        };
    } else {
        timeline_source.data = {
            date: [],
            tr: [],
            rank: [],
        };
    }

    graph_source.data = {
        date: dates,
        tr_ys: values[0],
        vs_ys: values[1],
        pps_ys: values[2],
        apm_ys: values[3],
        player: selected_players,
        color: range(selected_players.length).map(i => palette[i % palette.length]),
    };
}
