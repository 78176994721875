import { get_elem, on_click } from "../../util";

function snapshot() {
    const bottom = get_elem("player-card");
    const player_name = get_elem("stats-username").textContent;
    const date = get_elem("last-updated")
        .textContent
        .replace("last updated: ", "")
        .replace(/\s+/g, "_")
        .replace(/:/g, "-");
    bottom.classList.toggle("in-snapshot");
    window.dispatchEvent(new Event("resize"));

    html2canvas(
        bottom,
        {
            backgroundColor: null,
            scale: 3,
        }
    ).then(function(canvas) {
        canvas.toBlob(
            blob => {
                const anchor = document.createElement("a");
                anchor.download = `tetrio_stats_${player_name}_${date}.png`;
                anchor.href = URL.createObjectURL(blob);

                anchor.click();
                URL.revokeObjectURL(anchor.href);
            },
            "image/png"
        );
        bottom.classList.toggle("in-snapshot");
        window.dispatchEvent(new Event("resize"));
    });
}

export function init_snapshot_button() {
    on_click("snapshot-button-anchor", snapshot);
}
