import { get_queries, get_elem } from "./dom";

let current: string;

export function kagari(className: string): void {
    if (current) {
        kagarii(current, false);
    }
    current = className;
    kagarii(className, true);
}

export function kogori(): void {
    if (!current) {
        return;
    }
    kagarii(current, false);
    current = undefined;
}

export function kagarii(className: string, enable: boolean): void {
    let classes = [".bevel-default", ".bevel-default-pressed", ".background-default"];
    let original = "default";
    let target = className;
    const footer = get_elem("footer-logo") as HTMLImageElement;

    if (!enable) {
        classes = [`.bevel-${className}`, `.bevel-${className}-pressed`, `.background-${className}`];
        original = className;
        target = "default";
        footer.src = "images/tenchi.png";
    }
    else {
        if (current === "windows") {
            footer.src = "https://upload.wikimedia.org/wikipedia/commons/1/1e/Windows_Logo_1995.svg";
        }
        else if (current === "kagari") {
            footer.src = "https://cdn.discordapp.com/emojis/676946417364762644.png?v=1";
        }
    }

    classes.flatMap(get_queries)
        .forEach(b => b.className = b.className.replace(original, target));
 
    get_queries(".content").forEach(p => {
        if (enable) {
            p.classList.add(className);
        } else {
            p.classList.remove(className);
        }
    });
 
    if (enable) {
        document.body.classList.add(className);
    } else {
        document.body.classList.remove(className);
    }
}
