export const get_elem: (id: string) => HTMLElement = document.getElementById.bind(document);

export const create_elem: (id: string) => HTMLElement = document.createElement.bind(document);

export function get_input(id: string): HTMLInputElement {
    return document.getElementById(id) as HTMLInputElement;
}

export function get_image(id: string): HTMLImageElement {
    return document.getElementById(id) as HTMLImageElement;
}

export function get_query(query: string): HTMLElement {
    return document.querySelector(query);
}

export function get_queries(query: string): HTMLElement[] {
    return Array.from(document.querySelectorAll(query));
}

export function get_radios(name: string): HTMLInputElement[] {
    return Array.from(document.getElementsByName(name) as NodeListOf<HTMLInputElement>);
}

export function get_radio(id: string): HTMLInputElement {
    return document.getElementById(id) as HTMLInputElement;
}

export function on_click(id: string, callback: (event: Event) => void) {
    get_elem(id).addEventListener("click", callback);
}
