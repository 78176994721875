import type * as TBokeh from "bokehjs";
import type { PlayersData, StatsWithZRank, Stat } from "../../types";

import { get_radios, range } from "../../util";
import { theme, Category10_10, patch_plot_theme } from "../../bokeh";

const plt = Bokeh.Plotting;
const palette = Category10_10;

const radar_label_distance = 1.1;
const radar_margin = 18;
const n_grid_lines = 4;
const boundary = 100 + radar_margin;

const empty_radar_tick_data: Record<string, number[]> = { angle: <number[]>[] };
range(n_grid_lines).forEach(i => {
    empty_radar_tick_data["line" + i] = <number[]>[];
    empty_radar_tick_data["x" + i] = <number[]>[];
    empty_radar_tick_data["y" + i] = <number[]>[];
});

const radar_source = new Bokeh.ColumnDataSource({ data: { xs: [], ys: [], player: [], color: [] } });
const radar_tick_source = new Bokeh.ColumnDataSource({ data: empty_radar_tick_data });

function safe_get(players: PlayersData, p: string): StatsWithZRank {
    if (players.latest_stats.hasOwnProperty(p)) {
        return players.latest_stats[p];
    }
    return players.unranked_stats[p];
}

interface RadarStat {
    getter: (players: PlayersData, p: string) => number,
    label: Stat
}

const radar_stats: RadarStat[] = [
    { getter: (players, p) => safe_get(players, p).TR,  label: "TR" },
    { getter: (players, p) => safe_get(players, p).VS,  label: "VS" },
    { getter: (players, p) => safe_get(players, p).apm, label: "apm" },
    { getter: (players, p) => safe_get(players, p).pps, label: "pps" },
    { getter: (players, p) => safe_get(players, p).GW,  label: "GW" },
    { getter: (players, p) => safe_get(players, p).WR,  label: "WR" },
];

const start_angle = Math.PI / 2
const angles = range(radar_stats.length).map(n => n * 2 * Math.PI / radar_stats.length + start_angle);

function polar_x(a: number, r: number): number {
    return 100 * Math.cos(angles[a]) * r;
}

function polar_y(a: number, r: number): number {
    return 100 * Math.sin(angles[a]) * r;
}

function dereverse(a: number): number {
    return (a > Math.PI / 2 && a < 3 * Math.PI / 2) ? a - Math.PI : a;
}

function format_tick(n: number): string {
    if (n < 100) {
        return n.toFixed(2);
    } else {
        return n.toFixed(0);
    }
}

export function init_radar(): TBokeh.LayoutDOM {
    const radar = plt.figure({
        x_axis_type: null,
        y_axis_type: null,
        x_range: [-boundary, boundary],
        y_range: [-boundary, boundary],
        min_border: 0,
        sizing_mode: "scale_both",
        aspect_ratio: 1,
        toolbar_location: null,
        tools: ""
    });

    patch_plot_theme(radar);

    // @ts-ignore
    radar.xgrid.grid_line_color = null;
    // @ts-ignore
    radar.ygrid.grid_line_color = null;
    radar.outline_line_color = null;
    radar.background_fill_color = null;

    const grid_radii = range(1, n_grid_lines + 1).map(n => n * ((100) / n_grid_lines));

    // Radar background
    radar.circle(
        0, 0,
        {
            radius: 100,
            fill_color: theme.attrs.Figure.background_fill_color,
            line_color: null
        }
    );
    // Grid circles
    radar.circle(
        0, 0,
        {
            radius: grid_radii,
            fill_color: null,
            line_color: theme.attrs.Grid.grid_line_color,
            alpha: theme.attrs.Grid.grid_line_alpha,
        }
    );
    // Axis lines
    radar.annular_wedge(
        0, 0,
        0, 100,
        angles, angles,
        {
            color: theme.attrs.Grid.grid_line_color,
            alpha: theme.attrs.Grid.grid_line_alpha,
        }
    );
    // Axis labels
    radar.text(
        range(radar_stats.length).map(i => polar_x(i, radar_label_distance)),
        range(radar_stats.length).map(i => polar_y(i, radar_label_distance)),
        radar_stats.map(s => s.label),
        {
            angle: angles.map(a => dereverse(a - start_angle)),
            text_color: theme.attrs.Axis.axis_label_text_color,
            text_font: theme.attrs.Axis.axis_label_text_font,
            text_font_size: theme.attrs.Axis.axis_label_text_font_size,
            // @ts-ignore
            text_font_style: theme.attrs.Axis.axis_label_text_font_syle,
            text_baseline: "middle",
            text_align: "center",
        }
    )

    // Tick labels
    range(n_grid_lines).forEach(j => {
        radar.text(
            { field: "x" + j },
            { field: "y" + j },
            { field: "line" + j },
            {
                source: radar_tick_source,
                angle: { field: "angle" },
                text_color: theme.attrs.Axis.axis_label_text_color,
                text_font: theme.attrs.Axis.axis_label_text_font,
                text_font_size: "10px",
                // @ts-ignore
                text_font_style: theme.attrs.Axis.axis_label_text_font_syle,
                text_baseline: "middle",
                text_align: "center",
                alpha: 0.7,
            }
        )
    });
    // Values
    const values = radar.patches(
        { field: "xs" },
        { field: "ys" },
        {
            source: radar_source,
            line_width: 2,
            fill_alpha: 0.3,
            fill_color: { field: "color" },
            line_color: { field: "color" },
        }
    )
    const hover = new Bokeh.HoverTool({
        tooltips: [
            ["", '<span style="color: @color;">@player</span>'],
        ],
        renderers: [values],
        point_policy: "follow_mouse",
        show_arrow: false,
    });
    radar.add_tools(hover);


    // Watermark
    radar.image_url(
        "images/logo.png",
        0, -5,
        191 / 1.5 , 30 / 1.5,
        {
            anchor: "bottom_center",
            global_alpha: 0.1,
        }
    )
    radar.text(
        0, -5,
        "TETRIO STATS by TENCHI",
        {
            text_color: theme.attrs.Axis.axis_label_text_color,
            text_font: theme.attrs.Axis.axis_label_text_font,
            text_font_size: "10px",
            // @ts-ignore
            text_font_style: theme.attrs.Axis.axis_label_text_font_syle,
            text_baseline: "top",
            text_align: "center",
            alpha: 0.1,
        }
    )

    return radar;
}

function get_radar_option(): string {
    return get_radios("player-radar-type").find(r => r.checked).value;
}

export function update_radar(players: PlayersData, selected_players: string[]): void {
    const radar_option = get_radar_option();

    const scales = radar_stats.map(s => {
        if (radar_option === "server best") {
            return players.maximums[s.label];
        }
        else if (radar_option === "MEDIAN") {
            return players.medians[s.label];
        }
        else {
            return Math.max(
                ...selected_players.map(p => safe_get(players, p)[s.label])
            );
        }
    });

    const radar_xs = selected_players.map(p => {
        return range(radar_stats.length)
            .map(i => polar_x(i, radar_stats[i].getter(players, p) / scales[i]));
    });

    const radar_ys = selected_players.map(p => {
        return range(radar_stats.length)
            .map(i => polar_y(i, radar_stats[i].getter(players, p) / scales[i]));
    });

    radar_source.data = {
        xs: radar_xs,
        ys: radar_ys,
        player: selected_players,
        color: range(selected_players.length).map(i => palette[i % palette.length])
    };

    const radar_ticks: Record<string, number[] | string[]> = {
        angle: angles.map(a => dereverse(a - start_angle)),
    };
    range(n_grid_lines).forEach(i => {
        radar_ticks["line" + i] = range(radar_stats.length).map(j => format_tick(((i + 1) / n_grid_lines) * scales[j]));
        radar_ticks["x" + i] = range(radar_stats.length).map(j => polar_x(j, (1 + i) / n_grid_lines));
        radar_ticks["y" + i] = range(radar_stats.length).map(j => polar_y(j, (1 + i) / n_grid_lines));
    });
    radar_tick_source.data = radar_ticks;
}
