import type * as TBokeh from "bokehjs";
import type { LeagueData } from "../../types";

import { patch_plot_theme } from "../../bokeh";

const plt = Bokeh.Plotting;

export const histogram_x_source = new Bokeh.ColumnDataSource({ data: { bottom: [], top: [], left: [], right: [], tooltip: [] } });
export const histogram_y_source = new Bokeh.ColumnDataSource({ data: { bottom: [], top: [], left: [], right: [], tooltip: [] } });

export function init_histogram(axis: string, scatter: TBokeh.Plot) {
    const source = axis == "x" ? histogram_x_source : histogram_y_source;

    const hover = new Bokeh.HoverTool({
        tooltips: [
            ["", "@tooltip"],
        ]
    })

    const props = (
        axis === "x" ? {
            sizing_mode: "stretch_width",
            plot_height: 150,
            x_range: scatter.x_range,
            x_axis_location: "above",
            min_border_top: 25
        } : {
            sizing_mode: "stretch_height",
            plot_width: 150,
            y_range: scatter.y_range,
            y_axis_location: "right",
        }
    );

    // @ts-ignore
    const histogram = plt.figure({
        tools: "",
        toolbar_location: null,
        min_border: 0,
        ...props
    });

    histogram.quad({
        bottom: { field: "bottom" },
        top: { field: "top" },
        left: { field: "left" },
        right: { field: "right" },
        source,
        line_color: "white",
        color: "#3a5785",
        name: "quads"
    })

    // @ts-ignore
    histogram[axis === "x" ? "xgrid" : "ygrid"].grid_line_color = null;
    // @ts-ignore
    histogram[axis === "x" ? "yaxis" : "xaxis"][0].ticker.desired_num_ticks = 3;
    histogram.add_tools(hover);
    patch_plot_theme(histogram);

    return histogram;
}

export function histogram_tooltips(league: LeagueData, values: number[], edges: number[], measure_label: string) {
    let result = [];
    const n_users = league.user_names.length;

    return values.map((value, i) => {
        let start = edges[i].toFixed(2);
        let end = edges[i + 1].toFixed(2);
        let percent = (100 * value / n_users).toFixed(2);
        return `${value} (${percent}%) users have between ${start} and ${end} ${measure_label}`;
    });
}
