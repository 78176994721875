import type { PlayersData, ThresholdsData, StatsWithZRank, Stats, Stat } from "../../types";

import { unknown_player } from "../../constants";
import { get_elem, get_image } from "../../util";

export function update_card(players: PlayersData, thresholds: ThresholdsData, player_name: string): void {
    let player: StatsWithZRank;
    let player_top: Stats;
    let is_unknown = false;
    let is_unranked = false;
    
    if (player_name === unknown_player) {
        is_unknown = true;
        player = {
            TR: 99999, GR: 0,     glk: 9999, RD: 999,
            GW: 0,     GP: 0,     GL: 0,     WR: 0,
            apm: null, pps: null, VS: null,  rank: "z",
        };
        player_name = "unknown";
    }
    else if (players.unranked_stats.hasOwnProperty(player_name)) {
        player = players.unranked_stats[player_name];
        is_unranked = true;
    }
    else {
        player = players.latest_stats[player_name];
        player_top = players.top[player_name];
    }

    const set_text = (id: string, text: string) => { get_elem(id).textContent = text; };
    set_text("stats-username", player_name);
    set_text("stats-tr",       player.TR.toFixed(0));
    set_text("stats-gr",       (player.GR + 1).toFixed(0));
    set_text("stats-glk",      is_unknown ? "N/A" : player.glk.toFixed(0));
    set_text("stats-rd",       is_unknown ? "N/A" : (is_unranked ? "???" : player.RD.toFixed(0)));
    set_text("stats-gw",       player.GW.toFixed(0));
    set_text("stats-gp",       player.GP.toFixed(0));
    set_text("stats-wr",       (player.WR * 100).toFixed(2));

    let rank_image = "images/ranks/" + player.rank + ".png";
    if (player_name === "osk") {
        rank_image = "images/osk.svg";
    }
    // else if (player_name === "tenchi") {
    //     rank_image = "images/tenchi.png";
    // }

    get_image("stats-rank").src = rank_image;

    get_elem("stats-box").className = "stats bevel-rank-" + player.rank;
    for (let i = 0; i < thresholds.thresholds.length; ++i) {
        const t = thresholds.thresholds[i];
        if (t.threshold < player.TR) {
            get_image("stats-pr").src = "images/ranks/" + t.rank + ".png";
            break;
        }
    }

    const table = get_elem("stats-table");
    table.innerHTML = "";
    function add_row(name: string, stat: Stat, decimals: number): void {
        table.innerHTML += `
            <tr>
                <td>${name}</td>
                <td>${is_unknown || (player[stat] === null) ? "N/A" : (is_unranked && stat === "RD" ? "N/A" : player[stat].toFixed(decimals))}</td>
                <td>${is_unknown || is_unranked ? "N/A" : "#" + (player_top[stat] + 1)}</td>
                <td>${is_unknown || is_unranked ? "N/A" : ((100 * player_top[stat] / players.total_players).toFixed(2)) + "%"}</td>
            </tr>
        `;
    }
    add_row("apm",          "apm", 2);
    add_row("pps",          "pps", 2);
    add_row("VS",           "VS",  2);
    add_row("win rate",     "WR",  2);
    add_row("games played", "GP",  0);
    add_row("games won",    "GW",  0);
    add_row("games lost",   "GL",  0);
    add_row("TR",           "TR",  0);
    add_row("glicko",       "glk", 0);
    add_row("RD",           "RD",  0);
}
