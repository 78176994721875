import type { RLEArray } from "../types";

export function decode_rle(rle: RLEArray): any[] {
    return rle
        .split(";")
        .map(group => group.split(":"))
        .slice(0, -1)
        .map(tuple => Array(parseInt(tuple[0])).fill(JSON.parse(tuple[1])))
        .reduce((accu, array) => accu.concat(array), []);
}
