import type { GhostColor, Block, Mino, Queue, PreviewerParams } from "./types";
import { get_elem, get_queries, create_elem } from "./util";

export const blocks: Record<Block, [string, number | null]> = {
    "_": ["empty",   null],
    "#": ["garbage",    9],
    "@": ["hurry",     10],
    ".": ["ghost",      7],
    "i": ["i-block",    4],
    "l": ["l-block",    1],
    "j": ["j-block",    5],
    "s": ["s-block",    3],
    "z": ["z-block",    0],
    "o": ["o-block",    2],
    "t": ["t-block",    6],
    "h": ["hold",       8],
    "x": ["x",         11],
}

const pieces: Record<Mino, [number, string, string]> = {
    "i": [4, "1h", "iiii"],
    "l": [3, "3w", "__llll"],
    "j": [3, "3w", "j__jjj"],
    "s": [3, "3w", "_ssss_"],
    "z": [3, "3w", "zz__zz"],
    "o": [2, "2w", "oooo"],
    "t": [3, "3w", "_t_ttt"],
}

export function map_to_divs(id: string, map: string, ghost_color: GhostColor, breakpoint?: number, isHeld?: boolean): string {
    const ghost_style = (char: string) => char === "." ? `ghost ${ghost_color === "_" ? "" : ("ghost-" + ghost_color)}` : "";
    return [...map]
        .map((char: Block, i) => `<div class="block ${id}-${isHeld && char != "_" ? blocks["h"][0] : blocks[char][0]} ${breakpoint === i ? "block-reset": ""} ${ghost_style(char)}"></div>`)
        .join("");
}

export function tetromino_div(id: string, piece: Mino, ghost_color: GhostColor, isHeld?: boolean): string {
    const [width, paddingClass, map] = pieces[piece]
    return `
        <div class="tetromino-container">
            <div class="tetromino tetromino-${paddingClass}">
                ${map_to_divs(id, map, ghost_color, width, isHeld)}
            </div>
        </div>
    `;
}

function create_skin_preview(id: string, hold: Mino, map: string, queue: Queue, ghost_color: GhostColor): string {
    return `
        <div class="preview-container">
            <div class="preview">
                <div class="hold">${tetromino_div(id, hold, ghost_color, true)}</div>
                <div class="field-container">
                    <div class="field">
                        ${map_to_divs(id, map, ghost_color)}
                    </div>
                </div>
                <div class="queue">${[...queue].map(q => tetromino_div(id, q, ghost_color)).join("")}</div>
            </div>
        </div>`;
}

function add_skin_styles(id: string, skin: string, ghost_color: GhostColor, background: string) {
    const target = get_elem(id);

    const style = create_elem("style") as HTMLStyleElement;
    style.id = `${id}-style`;
    style.type = "text/css"

    style.textContent = Object
        .values(blocks)
        .map(b => {
            if (b[1] === null) return "";
            // FIXME: interface for blocks
            // FIXME: magic numbers
            return `
                .${id}-${b[0]} {
                    background-image: url("${skin}");
                    background-position: ${b[1] * 100 * 31 / (372 - 30)}% 0;
                    background-size: 1240% 100%; /* 372 / 30 */
                }`;
        })
        .join("");

    if (background === null) {
        background = `https://tetr.io/res/bg/${Math.floor(Math.random() * 35) + 1}.jpg`;
    }

    style.textContent += `
        #${id} {
            cursor: zoom-in;
        }
        #${id}.full-screen {
            cursor: zoom-out;
            z-index: 2;
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw !important;
            height: 100vh !important;
            perspective: 1px;
            perspective-origin: 0 0;
        }
        #${id}.full-screen:before {
            content: " ";
            opacity: 0;
            position: fixed;
            left: -5vw;
            top: -5vh;
            width: 110vw;
            height: 110vh;
            background: ${background.startsWith("http") ? `url(${background})` : background};
            background-size: cover;
            background-position: 50%;
            transform-origin: 0 0;
            transform: translateZ(-2px) scale(3);

            animation-name: skp-fade-in;
            animation-iteration-count: 1;
            animation-timing-function: ease-in-out;
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
        }
        #${id}.full-screen .preview-container {
            max-width: 90vh !important;
            margin: auto;
            margin-top: max(7vh, calc((100vh - 100vw) / 2));
        }`;
    document.head.appendChild(style);

    target.addEventListener("mousemove", (e) => {
        if (target.classList.contains("full-screen")) {
            target.style.perspectiveOrigin = `${(3 * -e.pageX / window.innerWidth) - 1.5}% ${(3 * -e.pageY / window.innerHeight) - 1.5}%`;
        }
    });
}

export const default_previewer_params: Partial<PreviewerParams> = {
    queue: ["l", "t", "j", "z", "i"],
    hold: "o",
    ghost_color: "t",
    background: null,
    map: "_______________________________________________________t_________tt________t______________i_z_______izz____s__izlll__ss_izlss._js_zzss_..jooziiii.jjoo#####_#############__##########_########@@@@@@@@@@"
};

export function toggle_skin_preview(id: string): void {
    const target = get_elem(id);
    target.classList.toggle("full-screen");
}

document.addEventListener("keydown", (event: KeyboardEvent) => {
    if (event.keyCode === 27) {  // ESC
        const targets = get_queries(".full-screen");
        [...targets].forEach(target => {
            target.classList.toggle("full-screen");
        });
    }
});

export function skin_previewer(params: PreviewerParams) {
    const { id, skin, queue, hold, map, ghost_color, background } = {...default_previewer_params, ...params};
    const target = document.getElementById(id);
    target.classList.add("skp");
    target.onclick = () => toggle_skin_preview(id);
    add_skin_styles(id, skin, ghost_color, background);
    target.innerHTML = create_skin_preview(id, hold, map, queue, ghost_color);
}
