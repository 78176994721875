import type * as TBokeh from "bokehjs";

import { theme } from "./theme"
import { patch_object, patch_property } from "../util"

export function patch_plot_theme(plot: TBokeh.Plotting.Figure) {
    patch_object(plot, theme["attrs"]["Figure"]);
    patch_property(plot, "xgrid", theme["attrs"]["Grid"]);
    patch_property(plot, "ygrid", theme["attrs"]["Grid"]);
    patch_property(plot, "xaxis", theme["attrs"]["Axis"]);
    patch_property(plot, "yaxis", theme["attrs"]["Axis"]);
    patch_property(plot, "legend", theme["attrs"]["Legend"]);
}
