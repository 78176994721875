import { get_radios, get_input } from "./util";

function switch_to_tab(tab_id: string): void {
    const tab = get_input(`tab-${tab_id}`);
    if (tab && !tab.disabled) {
        tab.checked = true;
    }
}

export function switch_to_queried_tab(): void {
    const url_params = new URLSearchParams(window.location.search);
    if (url_params.has("t")) {
        const tab_id = url_params.get("t");
        switch_to_tab(tab_id);
    }
}

export function add_tab_listeners(): void {
    get_radios("tabs").forEach(button => {
        button.onclick = () => {
            const url_params = new URLSearchParams(window.location.search);
            url_params.set("t", button.id.replace("tab-", ""));
            const new_path = `${window.location.pathname}?${url_params.toString()}${location.hash}`;
            history.replaceState(null, "", new_path);
        };
    });

}
