export const Category10_10 = ["#2293e3", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf"];

export const theme = {
    "attrs": {
        "Figure": {
            "background_fill_color": "rgba(100, 100, 160, 0.1)",
            "border_fill_color": "rgba(0, 0, 0, 0)",
            "outline_line_color": "#E0E0E0",
            "outline_line_alpha": 0.25
        },
        "Grid": {
            "grid_line_color": "#E0E0E0",
            "grid_line_alpha": 0.25
        },
        "Axis": {
            "major_tick_line_alpha": 0,
            "major_tick_line_color": "#E0E0E0",
            "minor_tick_line_alpha": 0,
            "minor_tick_line_color": "#E0E0E0",
            "axis_line_alpha": 0,
            "axis_line_color": "#E0E0E0",
            "major_label_text_color": "#E0E0E0",
            "major_label_text_font": "HUN",
            "major_label_text_font_size": "1.025em",
            "axis_label_standoff": 10,
            "axis_label_text_color": "#E0E0E0",
            "axis_label_text_font": "HUN",
            "axis_label_text_font_size": "1.25em",
            "axis_label_text_font_style": "normal"
        },
        "Legend": {
            "spacing": 8,
            "glyph_width": 15,
            "label_standoff": 8,
            "label_text_color": "#E0E0E0",
            "label_text_font": "HUN",
            "label_text_font_size": "1.025em",
            "border_line_alpha": 0.4,
            "background_fill_alpha": 1,
            "background_fill_color": "#1c2029",
            "border_line_color": "#E0E0E0"
        },
        "ColorBar": {
            "title_text_color": "#E0E0E0",
            "title_text_font": "HUN",
            "title_text_font_size": "1.025em",
            "title_text_font_style": "normal",
            "major_label_text_color": "#E0E0E0",
            "major_label_text_font": "HUN",
            "major_label_text_font_size": "1.025em",
            "background_fill_color": "#15191C",
            "major_tick_line_alpha": 0,
            "bar_line_alpha": 0
        },
        "Title": {
            "text_color": "#E0E0E0",
            "text_font": "HUN",
            "text_font_size": "1.15em"
        }
    }
};
