import type { LeagueData } from "../../types";

import { patch_plot_theme } from "../../bokeh";

export const scatter_source = new Bokeh.ColumnDataSource({ data: { x: [], y: [], rank: [], name: [], tr: [], alpha: [] } });
export const scatter_stddev_source = new Bokeh.ColumnDataSource({ data: { base: [], lower: [], upper: [] }});

const plt = Bokeh.Plotting;


export function init_scatter(league: LeagueData) {
    const color_mapper = new Bokeh.CategoricalColorMapper({
        factors: league.ranks.map(r => r.rank),
        palette: league.ranks.map(r => r.color)
    });

    const hover = new Bokeh.HoverTool({
        tooltips: [
            ["name", "@name"],
            ["rank", "@rank (@tr{0} TR)"],
            ["x", "@x{0.00}"],
            ["y", "@y{0.00}"],
        ]
    })

    const scatter = plt.figure({
        tools: "",
        sizing_mode: "stretch_both",
        aspect_ratio: 1,
        min_border: 0,
        min_border_left: 10,
        min_border_bottom: 30,
        toolbar_location: null
    });

    scatter.add_tools(hover);
    patch_plot_theme(scatter);

    scatter.circle(
        { field: "x" },
        { field: "y" },
        {
            source: scatter_source,
            size: 5,
            // @ts-ignore
            legend: { field: "rank" },
            alpha: { field: "alpha" },
            // @ts-ignore
            color: { field: "rank", transform: color_mapper }
        }
    );

    const band = new Bokeh.Band({
        base: { field: "base" },
        lower: { field: "lower" },
        upper: { field: "upper" },
        source: scatter_stddev_source,
        level: "overlay",
        fill_alpha: 0.2,
        fill_color: "white",
        line_width: 1,
        line_color: "white",
        line_alpha: 0.7,
        name: "stddev band",
    });
    scatter.add_layout(band);

    scatter.legend.name = "circle legend";
    scatter.legend.location = "bottom_right";
    scatter.legend.padding = 4;
    scatter.legend.spacing = 0;

    return scatter;
}