import type { State, Frame, Block, InsertMode, Mino, GhostColor } from "../../types";

import { skin_previewer, blocks, default_previewer_params, tetromino_div, toggle_skin_preview } from "../../previewer";
import { range, get_radios, get_radio, get_elem, get_input, get_query, get_queries, on_click, create_elem } from "../../util";

export function init() {

// ------------------------------------------------------------------------------------------------
// Constants

const default_map = "_".repeat(10 * 20) + default_previewer_params.map;
const default_skin = "images/skins/minos.svg";

skin_previewer({
    id: "editor-preview",
    skin: default_skin,
    map: default_map.slice(10 * 20),
});

const blocks_by_class: Record<string, string> =
    Object.keys(blocks)
        .reduce(
            (map, key: Block) => { map[`editor-preview-${blocks[key][0]}`] = key; return map; },
            {} as Record<string, string>
        );

const editor                = get_elem("editor-preview");
const preview_container     = get_query("#editor-preview .preview-container");
const field                 = get_query("#editor-preview .field");
const frame_counter         = get_elem("number-frames");
const skin_input            = get_input("editor-skin");
const caption_input         = get_input("editor-caption");
const hold                  = get_query("#editor-preview .hold");
const queue                 = get_query("#editor-preview .queue");
const stylesheet            = get_elem("editor-preview-style");
const selected_block_radios = get_radios("editor-selected-block");
const insert_mode_radios    = get_radios("editor-insert-mode");
const hold_piece_radios     = get_radios("editor-hold-piece");
const ghost_color_radios    = get_radios("editor-ghost-color");
const editor_display_radios = get_radios("editor-editor-display");
const editor_show_top_radio = get_radio("editor-editor-display-top 20 rows");
const smoothing_radios      = get_radios("editor-toggle-smoothing");
const hold_active_radios    = get_radios("editor-hold-active");
const queue_pieces_radios   = range(5).map(i => get_radios(`editor-queue-piece-${i}`));

const all_blocks = Array.from(get_queries("#editor-preview .field .block"));
const blocks_grid = range(20).map(i => all_blocks.slice(i * 10, (i + 1) * 10));

const caption_box = init_caption_box();

// ------------------------------------------------------------------------------------------------
// Variables

const state: State = {
    current_frame: 0,
    frames: [{
        map: default_map,
        hold: "o",
        hold_active: false,
        queue: ["l", "t", "j", "z", "i"],
        ghost: "t",
        caption: null
    }],
}

let is_playing = false;
let showTop = false;

update_caption();

// ------------------------------------------------------------------------------------------------
// Helpers

function get_current_frame(): Frame {
    return state.frames[state.current_frame];
}

function copy_frame(): Frame {
    const frame = get_current_frame();
    return {
        map: frame.map,
        hold: frame.hold,
        hold_active: frame.hold_active,
        queue: [...frame.queue],
        ghost: frame.ghost,
        caption: frame.caption
    }
}

function selected_block(): Block {
    return selected_block_radios.find(r => r.checked)?.value.toLowerCase() as Block;
};

function insert_mode(): InsertMode {
    return insert_mode_radios.find(r => r.checked)?.value.toLowerCase() as InsertMode;
}

function clear(): void {
    get_current_frame().map = "_".repeat(10 * 40);
    load_current_frame();
}

// TODO: accept any multiple of 10 and pad to bottom
function load_map(map: string): void {
    get_current_frame().map = map;
    const ghostColor = get_current_frame().ghost;
    const ghostStyle = (b: string) => b === "." ? `ghost ${ghostColor === "_" ? "" : ("ghost-" + ghostColor)}` : "";
    const toDraw = showTop ? map.slice(0, 10 * 20) : map.slice(10 * 20);
    [...toDraw].forEach((b: Block, i) => {
        all_blocks[i].className = `block editor-preview-${blocks[b][0]} ${ghostStyle(b)}`
    });
    update_caption();
}

function dump_map(): string {
    return all_blocks.map(b => blocks_by_class[b.classList[1]]).join("");
}

function update_counter(): void {
    frame_counter.textContent = `${state.current_frame + 1} / ${state.frames.length}`;
    update_caption();
}

function change_skin(skin: string): void {
    skin_input.value = skin;
    skin_input.dispatchEvent(new Event("change"));
}

function update_style_backgrounds(): void {
    const newSkin = skin_input.value || default_skin;
    stylesheet.textContent = stylesheet.textContent
        .replace(
            /^(\s+)background-image.*;$/gm,
            (m, p1) => `${p1}background-image: url("${newSkin}");`
        );
}

function change_hold(hold_piece: Mino | "_"): void {
    if (hold_piece === "_") {
        hold.innerHTML = "";
    } else {
        hold.innerHTML = tetromino_div("editor-preview", hold_piece, "_", !get_current_frame().hold_active);
    }
    get_current_frame().hold = hold_piece;
    hold_piece_radios.forEach(r => { r.checked = r.value.toLowerCase() === hold_piece; });
}


function change_ghost(ghost_color: GhostColor): void {
    const frame = get_current_frame();
    frame.ghost = ghost_color;
    load_map(frame.map);
    ghost_color_radios.forEach(r => { r.checked = r.value.toLowerCase() === ghost_color; });
}

function change_queue(i: number, queue_piece: Mino) {
    queue.children[i].outerHTML = tetromino_div("editor-preview", queue_piece, "_", false);
    get_current_frame().queue[i] = queue_piece;
    queue_pieces_radios[i].forEach(r => { r.checked = r.value.toLowerCase() === queue_piece; });
}

function change_hold_active(hold_active: boolean): void {
    const frame = get_current_frame();
    frame.hold_active = hold_active;
    change_hold(frame.hold);
    hold_active_radios.forEach(r => { r.checked = (r.value === "hold active") && hold_active || (r.value === "hold inactive") && !hold_active; });
}

function change_caption(caption: string): void {
    get_current_frame().caption = caption;
    update_caption();
}

function get_current_map(): string {
    return get_current_frame().map;
}

function update_state_map(): void {
    const currentMap = get_current_map();
    const top = showTop ? dump_map() : currentMap.slice(0, 10 * 20);
    const bottom = showTop ? currentMap.slice(10 * 20) : dump_map();
    get_current_frame().map = top + bottom;
    update_caption();
}

function load_current_frame(): void {
    const frame = get_current_frame();
    load_map(frame.map);
    change_hold_active(frame.hold_active);
    change_hold(frame.hold);
    change_ghost(frame.ghost);
    update_caption_input();
    [...frame.queue].forEach((qp, i) => change_queue(i, qp));
}

function update_caption(): void {
    const frame = get_current_frame();
    const caption = (frame.caption === null || frame.caption.length === 0)
        ? ""
        : `
            <div class="caption-title">caption</div>
            <div class="caption-content caption-small caption-text">${frame.caption}</div>
        `;
    caption_box.innerHTML = `
        ${caption}
        <div class="caption-title">blocks</div>
        <div class="caption-content">${[...get_current_map()].filter(c => "zlojist#@".includes(c)).length}</div>
        <div class="caption-title">frame</div>
        <div class="caption-content">${state.current_frame + 1}<div class="caption-small">/${state.frames.length}</div></div>
    `;
}

function update_caption_input(): void {
    caption_input.value = get_current_frame().caption;
    update_caption();
}

// ------------------------------------------------------------------------------------------------
// Board

editor.onclick = undefined;  // TODO: Have an onclick for full-screen exit
on_click("editor-full-screen", () => toggle_skin_preview("editor-preview"));

function is_hovered(block_x: number, block_y: number, mouse_x: number, mouse_y: number): boolean {
    switch (insert_mode()) {
        case "block":
            return block_x === mouse_x && block_y === mouse_y;
        case "garbage":
            return block_y === mouse_y && block_x !== mouse_x;
        case "row":
            return block_y === mouse_y;
        default:
            return false;
    }
}

const iterate_board = (action: (x: number, y: number) => void) => {
    for (let y = 0; y < blocks_grid.length; ++y) {
        for (let x = 0; x < blocks_grid[0].length; ++x) {
            action(x, y);
        }
    }
}

const ghost_style = (b: Block) => {
    const ghost_color = get_current_frame().ghost;
    return b === "." ? `ghost ${ghost_color === "_" ? "" : ("ghost-" + ghost_color)}` : "";
}

all_blocks.forEach((b, i) => {
    const mouseX = i % 10;
    const mouseY = Math.floor(i / 10);

    const draw = (e: Event) => {
        if (is_playing) {
            return;
        }
        iterate_board((x, y) => {
            const target = blocks_grid[y][x];
            if (is_hovered(x, y, mouseX, mouseY)) {
                const b = selected_block();
                blocks_grid[y][x].className = `block editor-preview-${blocks[b][0]} ${ghost_style(b)}`;
            }
            if (x == mouseX && y == mouseY && insert_mode() === "garbage") {
                blocks_grid[y][x].className = "block editor-preview-empty";
            }
        });
        update_state_map();
        e.preventDefault();
    };

    const hover = () => iterate_board((x, y) => {
        const target = blocks_grid[y][x];
        const should_be_hovered = is_hovered(x, y, mouseX, mouseY);
        if (target.classList.contains("editor-preview-empty")) {
            if (should_be_hovered) {
                const b = selected_block();
                blocks_grid[y][x].className = `block editor-preview-${blocks[b][0]} hover ${ghost_style(b)}`;
            }
        }
        else if (target.classList.contains("hover")) {
            if (!should_be_hovered) {
                blocks_grid[y][x].className = "block editor-preview-empty";
            }
        }
    });

    b.addEventListener("mousedown", draw);
    b.addEventListener("mouseenter", (e: MouseEvent) => {
        if (e.buttons === 1) draw(e);
        else hover();
    });
    b.addEventListener("mousemove", hover);
});

field.addEventListener("mouseleave", () => iterate_board((x, y) => {
    const target = blocks_grid[y][x];
    if (target.classList.contains("hover")) {
        blocks_grid[y][x].className = "block editor-preview-empty";
    }
}));

on_click("keyboard-toggle", () => {
    get_queries(".keyboard-shortcut").forEach(e => e.classList.toggle("display-none"));
});

function init_caption_box(): HTMLElement {
    const caption_box = create_elem("div");
    caption_box.classList.add("caption-box");
    preview_container.appendChild(caption_box);
    return caption_box;
}

// ------------------------------------------------------------------------------------------------
// Skin option group

skin_input.addEventListener("change",      () => update_style_backgrounds());
skin_input.addEventListener("keyup paste", () => update_style_backgrounds());
skin_input.addEventListener("keyup",       () => update_style_backgrounds());

on_click("editor-tetrio-button",    () => {change_skin("")});
on_click("editor-outris-button",    () => {change_skin("https://tetrio.team2xh.net/images/skins/outris/outris.svg")});
on_click("editor-ppt-button",       () => {change_skin("https://tetrio.team2xh.net/images/skins/ppt/ppt.svg")});
on_click("editor-hard-drop-button", () => {change_skin("https://tetrio.team2xh.net/images/skins/harddrop/harddrop.svg")});

function update_smoothing(): void {
    const pixelated = smoothing_radios.find(r => r.checked).value.toLowerCase() === "pixelated";
    editor.classList.toggle("block-pixelated", pixelated);
}

smoothing_radios.forEach(r => r.addEventListener("click", update_smoothing));

// ------------------------------------------------------------------------------------------------
// Map option group

on_click("editor-clear-button", clear);

on_click("editor-default-button", () => load_map(default_map));

on_click("editor-import-button", () => {
    clear();
    navigator
        .clipboard.readText()
        .then(map => load_map(map))
        .catch(err => console.error("Failed to read clipboard: ", err));
});

on_click("editor-export-button", () => {
    navigator
        .clipboard.writeText(get_current_map().replace(/(\.|x)/g, "_"))
        .catch(err => console.error("Failed to write to clipboard: ", err));
});

editor_display_radios.forEach(r => r.addEventListener("click", () => {
    showTop = editor_show_top_radio.checked;
    load_map(get_current_map());
}));

// ------------------------------------------------------------------------------------------------
// Movement option group

on_click("editor-move-up-button", () => {
    load_map(get_current_map().slice(10) + "__________");
});

on_click("editor-move-down-button", () => {
    load_map("__________" + get_current_map().slice(0, -10));
});

on_click("editor-move-left-button", () => {
    const map = get_current_map();
    load_map(range(40).map(i => map.slice(i * 10 + 1, i * 10 + 10) + "_").join(""));
});

on_click("editor-move-right-button", () => {
    const map = get_current_map();
    load_map(range(40).map(i => "_" + map.slice(i * 10, i * 10 + 9)).join(""));
});

// ------------------------------------------------------------------------------------------------
// Hold, queue, ghost

const update_hold = () => change_hold(hold_piece_radios.find(r => r.checked).value.toLowerCase() as Mino);
const update_ghost = () => change_ghost(ghost_color_radios.find(r => r.checked).value.toLowerCase() as GhostColor);
const update_queue = (i: number) => () => change_queue(i, queue_pieces_radios[i].find(r => r.checked).value.toLowerCase() as Mino);
const update_hold_active = () => change_hold_active(hold_active_radios.find(r => r.checked).value.toLowerCase() === "hold active");

hold_piece_radios.forEach(r => r.addEventListener("click", update_hold));
ghost_color_radios.forEach(r => r.addEventListener("click", update_ghost));
range(5).forEach(i => queue_pieces_radios[i].forEach(r => r.addEventListener("click", update_queue(i))));
hold_active_radios.forEach(r => r.addEventListener("click", update_hold_active));

// ------------------------------------------------------------------------------------------------
// Sequence option group

document.addEventListener("keydown", (event: KeyboardEvent) => {
    const url_params = new URLSearchParams(window.location.search);
    if (url_params.has("t")) {
        const tab_id = url_params.get("t");
        if (tab_id != "editor") return;
    } else {
        return;
    }
    if ((<Element[]>[skin_input, caption_input]).includes(document.activeElement)) {
        return;
    }

    function click(id: string, allowed_during_play = false) {
        if (is_playing && !allowed_during_play) {
            return;
        }
        get_elem(`editor-${id}-button`).dispatchEvent(new Event("click"))
    };

    switch (event.keyCode) {
        case 37:
            return click("previous");
        case 39:
            return click("next");
        case 32:
            click("play-pause", true);
            return event.preventDefault();
        case 78:
            return click("increment-frames");
    }
});

on_click("editor-decrement-frames-button", () => {
    if (state.frames.length == 1) {
        return;
    }
    if (state.current_frame == state.frames.length - 1) {
        --state.current_frame;
    }
    state.frames.pop();
    if (state.current_frame == state.frames.length - 1) {
        load_current_frame();
    }
    update_counter();
});

on_click("editor-increment-frames-button", () => {
    const new_frame = copy_frame();
    new_frame.caption = null;
    state.frames.push(new_frame);
    if (state.current_frame == state.frames.length - 2) {
        ++state.current_frame;
        update_caption_input();
    }
    update_counter();
});

on_click("editor-beginning-button", () => {
    state.current_frame = 0;
    update_counter();
    load_current_frame();
});

on_click("editor-end-button", () => {
    state.current_frame = state.frames.length - 1;
    update_counter();
    load_current_frame();
});

on_click("editor-previous-button", () => {
    if (state.current_frame == 0) {
        return;
    }
    --state.current_frame;
    update_counter();
    load_current_frame();
});

on_click("editor-next-button", () => {
    if (state.current_frame == state.frames.length - 1) {
        return;
    }
    ++state.current_frame;
    update_counter();
    load_current_frame();
});

const buttons_to_disable_while_playing = [
    "clear", "default", "import", "export",
    "decrement-frames", "increment-frames",
    "beginning", "previous", "next", "end",
    "move-up", "move-down", "move-left", "move-right",
]

const radios_to_disable_while_playing = [
    ...hold_piece_radios.map(r => r.id),
    ...ghost_color_radios.map(r => r.id),
    ...queue_pieces_radios.flatMap(rs => rs.map(r => r.id)),
];

function disable_buttons(): void {
    buttons_to_disable_while_playing.forEach(n => get_elem(`editor-${n}-button`).setAttribute("disabled", ""));
    radios_to_disable_while_playing.forEach(id => get_elem(id).setAttribute("disabled", ""));
}

function enable_buttons(): void {
    buttons_to_disable_while_playing.forEach(n => get_elem(`editor-${n}-button`).removeAttribute("disabled"));
    radios_to_disable_while_playing.forEach(id => get_elem(id).removeAttribute("disabled"));
}

function set_play_button(): void {
    get_elem("pause-icon").style.display = "none";
    get_elem("play-icon").style.display = "inline-block";
}

function set_pause_button() {
    get_elem("play-icon").style.display = "none";
    get_elem("pause-icon").style.display = "inline-block";
}

let animation: ReturnType<typeof setTimeout>;

function animate(): void {
    animation = setTimeout(() => {
        ++state.current_frame;
        update_counter();
        load_current_frame();
        if (state.current_frame == state.frames.length - 1) {
            set_play_button();
            enable_buttons();
            is_playing = false;
            return;
        }
        animate();
    }, 1000);
}

on_click("editor-play-pause-button", () => {
    if (is_playing) {
        clearTimeout(animation);
        set_play_button();
        enable_buttons();
        is_playing = false;
        return;
    }
    if (state.current_frame == state.frames.length - 1) {
        return;
    }
    set_pause_button();
    disable_buttons();
    is_playing = true;
    animate();
});

caption_input.addEventListener("change",      () => change_caption(caption_input.value));
caption_input.addEventListener("keyup paste", () => change_caption(caption_input.value));
caption_input.addEventListener("keyup",       () => change_caption(caption_input.value));

// TODO: export format should include hold and queue: map?queue?hold
// TODO: every listener will change the url params (base64)
// TODO: load params on page load
// TODO: mouse exit full screen
// TODO: state history, undo + redo
// TODO: insert mode for pieces + rotations
// TODO: gravity for insert mode
// TODO: auto-clear lines?
// TODO: auto-advance?
// TODO: caption-box inside grid
// TODO: fix long captions and trim caption
// TODO: lock decr/prev/play/next until there's at least 2 frames

}
