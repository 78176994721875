import { skin_previewer, toggle_skin_preview } from "../../previewer"
import { get_elem, get_queries } from "../../util";

function skin_previous(id: string, i: number): void {
    const current = get_elem(`${id}${i}`);
    const previous_id = current.getAttribute("previous");
    const previous = get_elem(previous_id);
    current.style.display = "none";
    previous.style.removeProperty("display");
}

function skin_next(id: string, i: number): void {
    const current = get_elem(`${id}${i}`);
    const next_id = current.getAttribute("next");
    const next = get_elem(next_id);
    current.style.display = "none";
    next.style.removeProperty("display");
}

export function init_buttons() {
    get_queries(".skin-preview").forEach(skp => {
        skin_previewer({
            id: skp.id,
            skin: skp.getAttribute("skin"),
            background: skp.getAttribute("background")
        });
    });
    get_queries(".skin-nav-button").forEach(btn => {
        const callback = btn.getAttribute("data-type") == "next" ? skin_next : skin_previous;
        const id = btn.getAttribute("data-id");
        const i = parseInt(btn.getAttribute("data-i"));
        btn.addEventListener("click", () => {
            callback(id, i);
        });
    });
    get_queries(".preview-button").forEach(btn => {
        btn.addEventListener("click", () => {
            toggle_skin_preview(btn.getAttribute("data-id"));
        });
    });
}
