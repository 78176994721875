import type * as TBokeh from "bokehjs";
import type { LeagueData, LongStat } from "../../types";

import { get_radios, get_elem } from "../../util";

import { standard_deviation_from_point_cloud } from "./stddev";
import { init_scatter, scatter_source, scatter_stddev_source } from "./scatter";
import { init_histogram, histogram_x_source, histogram_y_source, histogram_tooltips } from "./histogram";

const plt = Bokeh.Plotting;
const n_stddev_bars = 50;


function set_callbacks(update_callback: () => void): void {
    const radios_x = get_radios("league-x");
    const radios_y = get_radios("league-y");
    const legend = get_elem("check-show legend");
    const stddev = get_elem("check-show stddev");
    [...radios_x, ...radios_y, legend, stddev].forEach(e => {
        e.onclick = update_callback;
    });

    const search = get_elem("league-search");
    search.addEventListener("change",      update_callback);
    search.addEventListener("keyup paste", update_callback);
    search.addEventListener("keyup",       update_callback);
}

function get_checked_radio(measure_labels: LongStat[], axis: string) {
    return measure_labels.indexOf(get_radios(`league-${axis}`).find(r => r.checked).value as LongStat);
}

function is_checked(option: string): boolean {
    const checkbox = get_elem(`check-${option}`) as HTMLInputElement;
    return checkbox.checked;
}

function update_sources(league: LeagueData, scatter: TBokeh.LayoutDOM, measure_labels: LongStat[], histogram_bins: number) {
    const x_measure = get_checked_radio(measure_labels, "x");
    const y_measure = get_checked_radio(measure_labels, "y");

    const legend = scatter.select<TBokeh.Legend>("circle legend")[0]
    if (is_checked("show legend")) {
        legend.visible = true;
    } else {
        legend.visible = false;
        // FIXME: do something about hover
    }

    const search = (get_elem("league-search") as HTMLInputElement).value;
    const searches = search.trim().split(/(\s+)/g).map(s => s.trim().toLowerCase()).filter(s => s !== "");
    const alpha = league.user_names.map(n => {
        if (!search || search === "") return 0.6;
        for (let i = 0; i < searches.length; ++i) {
            if (n.startsWith(searches[i])) return 1.0;
        }
        return 0.02;
    });
    update_url(search);

    scatter_source.data = {
        x: league.measures[x_measure].values.map(v => v === null ? NaN : v),
        y: league.measures[y_measure].values.map(v => v === null ? NaN : v),
        rank: league.user_ranks,
        name: league.user_names,
        tr: league.measures[0].values,
        alpha
    };


    const stddev_band = scatter.select<TBokeh.Band>("stddev band")[0]
    if (is_checked("show stddev")) {
        scatter_stddev_source.data = standard_deviation_from_point_cloud(league.measures[x_measure].values, league.measures[y_measure].values, n_stddev_bars);
        stddev_band.visible = true;
    } else {
        stddev_band.visible = false;
    }

    histogram_x_source.data = {
        bottom: Array(histogram_bins).fill(0),
        top: league.measures[x_measure].histogram,
        left: league.measures[x_measure].edges.slice(0, -1),
        right: league.measures[x_measure].edges.slice(1),
        tooltip: histogram_tooltips(
            league,
            league.measures[x_measure].histogram,
            league.measures[x_measure].edges,
            measure_labels[x_measure]
        )
    };

    histogram_y_source.data = {
        left: Array(histogram_bins).fill(0),
        right: league.measures[y_measure].histogram,
        top: league.measures[y_measure].edges.slice(0, -1),
        bottom: league.measures[y_measure].edges.slice(1),
        tooltip: histogram_tooltips(
            league,
            league.measures[y_measure].histogram,
            league.measures[y_measure].edges,
            measure_labels[y_measure]
        )
    }
}

function update_url(search: string): void {
    const url_params = new URLSearchParams(window.location.search);
    url_params.set("s", search);
    if (!search || search === "") {
        url_params.delete("s");
    }
    const new_path = `${window.location.pathname}?${url_params.toString()}${location.hash}`;
    history.replaceState(null, "", new_path);
}

function set_search_from_params() {
    const search = get_elem("league-search") as HTMLInputElement;
    const url_params = new URLSearchParams(window.location.search);
    if (url_params.has("s")) {
        console.log(url_params.get("s"));
        search.value = url_params.get("s");
    }
}

export function init(league: LeagueData): void {
    const histogram_bins = league.measures[0].histogram.length;
    const measure_labels = league.measures.map(m => m.label);

    const scatter = init_scatter(league);

    const histogram_x = init_histogram("x", scatter);
    const histogram_y = init_histogram("y", scatter);

    set_search_from_params();
    set_callbacks(() => update_sources(league, scatter, measure_labels, histogram_bins));

    const layout = plt.gridplot(
        [
            [histogram_x, null],
            [scatter, histogram_y]
        ],
        {
            sizing_mode: "stretch_both",
            merge_tools: false
        }
    );

    update_sources(league, scatter, measure_labels, histogram_bins);

    plt.show(layout, "#tetra-league")
}
