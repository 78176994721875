import { patch_plot_theme } from "../../bokeh";
import type { ThresholdsData, Rank } from "../../types";

import { new_formula_date, new_percentiles_date } from "../../constants";

export function init(thresholds: ThresholdsData): void {
    const plt = Bokeh.Plotting;

    const ranks: Rank[] = ["x", "u", "ss", "s+", "s", "s-", "a+", "a", "a-", "b+", "b", "b-", "c+", "c", "c-", "d+", "d"];
    const rank_colors = ["#FF5AFF", "#FF5D16", "#FFA830", "#FFDA0C", "#DDA41C", "#B79E2B", "#36B827", "#3EA750", "#35AA8C", "#4880B2", "#4357B5", "#5949BE", "#522278", "#67287B", "#6C417C", "#815880", "#856C84"];

    const graph = plt.figure({
        tools: "",
        toolbar_location: null,
        x_axis_type: "datetime",
        sizing_mode: "scale_both",
        aspect_ratio: 4 / 3,
    });

    ranks.map((r, i) => {
        graph.line(
            thresholds.history.date.map(d => d * 1000),
            thresholds.history[r],
            {
                line_width: 2,
                line_color: rank_colors[i]
            }
        );
    });

    const new_formula = new Bokeh.Span({
        location: new_formula_date,
        dimension: "height",
        line_color: "white",
        line_dash: [3, 3],
        line_width: 1,
        line_alpha: 0.5,
    });

    const new_formula_label = new Bokeh.Label({
        x: new_formula_date,
        y: 0,
        y_offset: 5,
        y_units: "screen",
        text: "new TR formula",
        render_mode: "canvas",
        angle: Math.PI / 2,
        text_align: "left",
        text_color: "white",
        text_font: "pfw",
        text_alpha: 0.5,
    });

    const new_percentiles = new Bokeh.Span({
        location: new_percentiles_date,
        dimension: "height",
        line_color: "white",
        line_dash: [3, 3],
        line_width: 1,
        line_alpha: 0.5,
    });

    const new_percentiles_label = new Bokeh.Label({
        x: new_percentiles_date,
        y: 0,
        y_offset: 5,
        y_units: "screen",
        text: "new percentiles",
        render_mode: "canvas",
        angle: Math.PI / 2,
        text_align: "left",
        text_color: "white",
        text_font: "pfw",
        text_alpha: 0.5,
    });

    const crosshair = new Bokeh.CrosshairTool({
        dimensions: "width",
        line_color: "white",
    });

    graph.add_layout(new_formula);
    graph.add_layout(new_formula_label);
    graph.add_layout(new_percentiles);
    graph.add_layout(new_percentiles_label);
    graph.add_tools(crosshair);

    patch_plot_theme(graph);

    plt.show(graph, "#rank-graph");
}
