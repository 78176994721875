import type { PlayersData } from "../../types";

import { kagari, kogori, set_caret, get_caret, get_elem } from "../../util";
import { Category10_10 } from "../../bokeh";

const palette = Category10_10;

export function search_and_color_names(players: PlayersData) {
    const search = get_elem("player-search");
    const words = search.textContent.split(/(\s+)/g);
    const valid_players: string[] = [];

    if (words.length === 1 && /\s+/.test(words[0])) {
        return valid_players;
    }

    const original_position = get_caret(search);

    // colorize and save valid names
    let new_html = "";
    let c = 0;
    words.forEach(p => {
        if (/\s+/.test(p)) {
            new_html += p.replace(/\s/g, "&nbsp;");
        }
        else if (players.latest_stats.hasOwnProperty(p.toLowerCase()) || players.unranked_stats.hasOwnProperty(p.toLowerCase())) {
            valid_players.push(p.toLowerCase());
            new_html += `<span style="color: ${palette[c % palette.length]}">${p}</span>`;
            c += 1;
        } else {
            new_html += p;
        }

        if (p.toLowerCase() === "kagari") {
            kagari("kagari");
        }
        if (p.toLowerCase() === "kogori") {
            kogori();
        }
        if (["windows95", "tetrio95"].includes(p.toLowerCase())) {
            kagari("windows");
        }
        if (p.toLowerCase() === "zaptorzap") {
            window.location.href = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
        }
    });

    search.innerHTML = new_html.trim();
    set_caret(search, original_position);

    const snapshot_button = get_elem("snapshot-button");
    if (valid_players.length > 1) {
        snapshot_button.classList.add("display-none");
    } else {
        snapshot_button.classList.remove("display-none");
    }

    return valid_players;
}
